import $ from 'jquery';
window.jQuery = $;
require('slick-carousel');

export default {
  init() {
    // JavaScript to be fired on all pages

    // Landing Page Carousel
    $('#page-landing .module-carousel').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 7000,
      fade: true,
      speed: 1500,
      adaptiveHeight: true
    });

    // Modal: Open
    $('.modal-open').click(e => {
      e.preventDefault();
      const modalTarget = $(e.currentTarget).attr('href');
      $(modalTarget).addClass('is-active');
    });

    // Modal: Close
    $('.modal-underlay, .modal-close').click(e => {
      e.preventDefault();
      $('.modal').removeClass('is-active');
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after init JS is fired

    // Add Class on load
    const loadedClass = 'is-loaded';
    const loadClasses = ['#header', '#content', '#footer', '#footer-social, #loader'];
    setTimeout(() => {
      $.each(loadClasses, (i,e) => {
        $(e).addClass(loadedClass);
      });
    }, '2500');
    setTimeout(() => {
      $('#loader').remove();
    }, '3500');
  },
};
